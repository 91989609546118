<template>
  <div class="table-responsive">
    <table v-if="referenceProfiles.length || expertReferenceProfiles.length" class="table table-references ">
      <thead>
        <th>Название</th>
      </thead>
      <tbody>
        <tr v-for="profile of expertReferenceProfiles" class="expert-profile" :key="profile.id">
          <td>
            <button
              @click="getRight('expertReferences') ? openReference(profile.id, false) : openReference(profile.id, true)"
              class="flex justify-content-between w-full table-references__row"
            >
              {{ profile.title }}
              <i v-if="getRight('expertReferences')" class="fal fa-pen"></i>
            </button>
          </td>
        </tr>
        <tr v-for="profile of referenceProfiles" :key="profile.id">
          <td>
            <button
              @click="openReference(profile.id, false)"
              class="flex justify-content-between w-full table-references__row"
            >
              {{ profile.title }}
              <i class="fal fa-pen"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="table-preloader">
        <preloader/>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useStore } from "vuex";
import Preloader from '../../Technical/Preloader.vue';
import useCheckRights from '../../../mixins/useCheckRights';
export default {
  components: { Preloader },
  name: "referencesInterface",
  setup(props, context) {
    const store = useStore(), {getRight} = useCheckRights();
    const referenceProfiles = computed(
      () => store.state.analytics.referenceProfiles
    ), expertReferenceProfiles = computed(() => store.state.analytics.expertReferenceProfiles);

    onBeforeMount(() => {
      store.dispatch("analytics/getReferenceProfiles");
    });

    const openReference = (id, readOnly) => {
      context.emit("openReference", {id, readOnly});
    };

    return {
      referenceProfiles,
      openReference,
      expertReferenceProfiles,
      getRight
    };
  },
};
</script>

<style lang="scss" scoped>
.table-references {
  .expert-profile{
    background-color: var(--cream-color);
    border-bottom: 2px solid white;
    td button{
    color: var(--dark-color);
    font-weight: 400;
    }

  }
  &__row {
    padding: 5px 0;
    font-size: 15.5px;
    font-weight: 300;
    i {
      font-size: 19px;
    }

    &:hover {
      i {
        color: var(--hover-color);
      }
    }
  }
}
</style>